@font-face {
  font-family: "NeutraText-Bold";
  src: url("./font/NeutraText-Bold.otf");
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("./font/OpenSans.ttf");
  font-style: normal;
}

@font-face {
  font-family: "PoppinsBold";
  src: url("./font/Poppins-Bold.ttf");
  font-style: normal;
}

@font-face {
  font-family: "PoppinsLight";
  src: url("./font/Poppins-Light.ttf");
  font-style: normal;
}

body {
  margin: 0 auto !important;
  font-family: "PoppinsLight", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* background: url(/src/images/ice-bg.png) no-repeat fixed;  */
  background-size: cover;
  /* max-width: 1400px; */
  background: black;
}

code {
  font-family: "PoppinsLight", sans-serif;
}

:root {
  --main-color: #fff;
  --text-color: #fff;
  --blue: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.animate-spin {
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.is-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  padding: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PoppinsBold", sans-serif;
  font-weight: 700 !important;
}

p,
span {
  font-family: "PoppinsLight" !important;
  /* letter-spacing: 1px; */
  font-size: 30px;
}

.small-font {
  font-size: 20px !important;
  font-style: normal;
}

.middeling {
  display: flex;
  justify-content: center;
  align-items: center;
}
hr {
  margin: 25px 0;
  color: var(--text-color) !important;
}
.link {
  color: var(--text-color);
}
.link:hover {
  color: var(--text-color);
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
}

button#wallet-connect-trustwallet,
button#wallet-connect-mathwallet,
button#wallet-connect-tokenpocket,
button#wallet-connect-binance\ chain\ wallet,
a.sc-jSgvzq.sc-bYEvvW.gOOvqZ.eHcJpX.sc-ehSDrC.jxUsPl,
a.sc-jSgvzq.sc-bYEvvW,
a.dLsgSS,
a.fhEmnm,
.sc-eCstlR.dbsNcU,
.sc-eCstlR.hmvUZa {
  display: none !important;
}

.sc-eggMyH,
.gxBphU,
.kwzbpv,
.cgllQK {
  border-bottom: none !important;
}

.jCGkwA,
.jXGSEw,
.hxWjzo,
.icJZux {
  background-color: white !important;
  opacity: 0.5 !important;
}

.dRkkFJ,
.dMWeoW,
.enGsZK,
.cRhUol {
  border: 2px solid white !important;
  color: #fff !important;
}

.cKrdj,
.gaOJzr,
.gZgwsy,
.eWygAy,
.jtSYNp,
.boCcds,
.dLsgSS,
.bcTHOB,
.hufYrk {
  fill: white !important;
}

.gbnmUs,
.blAmLo,
.ldHeLF,
.gUPczV,
.cFaosz,
.gmXJlb,
.DFvIQ,
.kCMxsx {
  color: white !important;
}

.fopDDk,
.iNrdsl {
  color: #fff !important;
  font-family: "PoppinsLight" !important;
  font-size: 20px !important;
}

.wallet_button.not-connected,
.wallet_button.connected {
  /* font-size: 20px !important; */
}

.modal-header .btn-close {
  color: #fff !important;
}

.around {
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
}
#art img {
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  width: 100%;
}
.roadmap {
  margin:50px auto;
  -webkit-transform: perspective(600px);
          transform: perspective(600px);
            -webkit-perspective: 600px;
          perspective: 600px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.roadmap img {
  -webkit-transform: rotateY(356deg);
  transform: rotateY(356deg);
}
.roadmap {
  -webkit-animation: moveOrigin 4s infinite ease-in-out alternate;
          animation: moveOrigin 4s infinite ease-in-out alternate;
}

#mint-wrapper {
  margin: 0 auto;
}
@-webkit-keyframes moveOrigin {
  from {
    -webkit-perspective-origin: left;
            perspective-origin: left;
  }
  to {
    -webkit-perspective-origin: right;
            perspective-origin: right;
  }
}

@keyframes moveOrigin {
  from {
    -webkit-perspective-origin: left;
            perspective-origin: left;
  }
  to {
    -webkit-perspective-origin: right;
            perspective-origin: right;
  }
}

@media only screen and (max-width: 768px) {
  .around {
    flex-direction: column;
  }
  textarea,
  input.text,
  input[type="text"],
  input[type="button"],
  input[type="submit"],
  .input-checkbox,
  select,
  option {
    -webkit-appearance: none;
  }
}
